import React from 'react'
import { FaCircle } from 'react-icons/fa'

export default function QAAnalyst() {
    return (
        <div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">

                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Client
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : CareCentrix– Hartford , Connecticut

                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Project
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Health Care.

                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Duration
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Dec 2016 -July 2017
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Technologies
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Edifacts, XML, EDI X-12, HIPAA, EDI 834,835,837, FACTS 5.3CLAIM TEST PRO
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">

                            Role
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : QA Analyst
                        </span>
                    </li>
                </ul>
            </div>
            <div className="p-6">
                <h2 className="text-lg md:text-xl lg:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Description: -
                </h2>
                <p className="text-xs md:text-sm lg:text-base text-gray-700">
                    Care Centrix is Leading provider of healthcare services and include Post-Acute Care Services, Home health and Home
                    Sleep, home infusion services assistants. The system provides a complete solution for electronic billing and interfaces
                    to EMR software and lab systems. The system includes different packages like Electronic Statement, Electronic Claims
                    Billing, and Eligibility Verification. The system was HIPAA compliant and included features like Advance
                    Appointment Scheduler and Eligibility Verification and Referral Tracking.
                </p>
            </div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Responsibilities:-
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Efficiently involved in the fit analysis process to develop work estimates to fit the client's feature needs.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Worked with the internal project team to flush out functional requirements for specific features and sprint
                            planning to plan the work per sprint.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Worked on the feature requirements review with clients to finalize the requirements, changes, additions and
                            finally approvals.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Created process flows, use cases, user stories, play books, launch books and configuration workbooks to hand
                            over requirements to the development team

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Assisted project teams during UAT and worked closely with the pm and QA team to make sure all the
                            requirements are covered and tested.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Reviewed the business requirements document with the business and development team to understand the
                            architecture and functionality of the application.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Involved for validation of claims workflow in to Claim 2.0, Members Enrollment, validating Members benefits
                            against different products and working in member’s accumulator.


                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px]" />
                        </span>
                        <div className="text-xs md:text-sm lg:text-base">
                            Part of the team for migration of HIPAA – EDI X12 4010 series to 5010 series for EDI Transaction code sets:
                            <div className="flex flex-col md:flex-row md:flex-wrap md:gap-x-4 mt-2">
                                <span>✔ 835 – Claim payment/Advice</span>
                                <span>✔ 837 – Claim and encounters</span>
                                <span>✔ 834 – Benefit enrollment and maintenance</span>
                            </div>
                        </div>
                    </li>



                </ul>
            </div>
            <div className="p-6">

                <p className="text-xs md:text-sm lg:text-base text-gray-700">
                    Worked with the testing team to successfully test the implementation of HIPAA 837 & 834 transactions to meet
                    Federal and State requirements

                </p>
            </div>
        </div>
    )
}
