import React from 'react'
import { FaCircle } from 'react-icons/fa'

export default function RPASupportDeveloper() {
    return (
        <div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex flex-row items-start">
                        <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Client
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Virginia Credit Union , Midlothian, VA
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Duration
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Mar 2019 – Till Now
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Technologies
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : UiPath Studio.
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Role
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : RPA Support Developer
                        </span>
                    </li>
                </ul>
            </div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Responsibilities:-
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        As a Support developer concentrating on more while monitoring the day-to-day production runs without fail.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Design, develop, and implement RPA solutions using UiPath to automate repetitive tasks and processes.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Create and maintain detailed technical documentation for RPA solutions, including process flow diagrams and
                        user guides.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Analyze business processes to identify automation opportunities and assess the feasibility of automating them.


                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Collaborate with business analysts and stakeholders to gather requirements and understand the end-to-end
                        process.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Deploy and manage RPA bots in various environments (development, testing, production) to ensure smooth
                        operation.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Configure UiPath Orchestrator to schedule, monitor, and manage RPA workflows.


                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Provide ongoing support and maintenance for deployed RPA solutions to ensure they run smoothly and
                        efficiently.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Troubleshoot and resolve issues related to RPA bots, including performance issues, errors, and exceptions.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Conduct thorough testing of RPA solutions to ensure they meet quality standards and function as expected.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Perform regular updates and optimizations to improve the performance and reliability of RPA bots.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Work closely with cross-functional teams, including IT, business units, and external vendors, to ensure
                        successful RPA implementation.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Provide training and support to end-users and stakeholders on the usage and benefits of RPA solutions.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Adhere to best practices and standards for RPA development and implementation to ensure consistency and
                        scalability.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Ensure compliance with company policies, security protocols, and industry regulations in all RPA activities.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Continuously explore and evaluate new RPA tools and technologies to enhance automation capabilities.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Propose and implement improvements to existing RPA processes to increase efficiency and reduce operational
                        costs.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Generate and maintain reports on RPA performance, including key metrics, success rates, and areas for
                        improvement.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Document all stages of the RPA lifecycle, from initial requirements gathering to final deployment and
                        maintenance.

                        </span>
                    </li>
                    

                </ul>
            </div>
        </div>
    )
}
