import React from 'react'
import { FaCircle } from 'react-icons/fa'

export default function PHPDeveloper() {
    return (
        <div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">

                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">

                            Client
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Enosis Solutions, Dhaka, Bangladesh

                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">

                            Duration
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Mar 2016- Nov 2016
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">

                            Technologies
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : PHP, Bootstrap, CMS, MySQL.
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">

                            Role
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Jr. PHP Developer
                        </span>
                    </li>
                </ul>
            </div>
            <div className="p-6">
                <h2 className="text-lg md:text-xl lg:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Description: -
                </h2>
                <p className="text-xs md:text-sm lg:text-base text-gray-700">
                    Enosis is a premier provider of software development and testing services. They craft compelling web, desktop, and
                    mobile applications for clients. As a part of the Jr. PHP developer, I'm involved to developed front-end, backend,
                    database web application
                </p>
            </div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Responsibilities:-
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        PHP & MySQL based web application development.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Develop software solutions by studying information needs, systems flow, data usage, and work processes.                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Understanding of open-source projects like Joomla, Drupal etc.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Demonstrable knowledge of web technologies including HTML, CSS, JavaScript, AJAX etc

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Good knowledge of relational databases, version control tools and of developing web services

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Worked on HTML, CSS, JavaScript, and their integration within a PHP environment.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Developed PHP models using object-oriented programming (oops) concepts.



                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Defined and developed the applications presentation layer using html5, css3, JavaScript, and jQuery

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        With close interaction with clients gathered and analyzed requirements and developed functionality
                        accordingly.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Used JavaScript/jQuery to make an interactive site. Responsible for handling the front-end side of the application
                        to make it high performance, rich and user-friendly.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Used PhpMyAdmin to handle the administration of MySQL over the world wide web.
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}
