import React from 'react'
import { FaCircle } from 'react-icons/fa'

export default function SDETEngineer() {
    return (
        <div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">

                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Client
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Sun trust Bank, Owing mill, MD

                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Duration
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Mar 2018-Feb 2019
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Technologies
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Agile, Java, Selenium WebDriver, TestNG, Cucumber, Maven, Jenkins, SQL, Unix ,Git, GitHub,
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Role
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : SDET Engineer
                        </span>
                    </li>
                </ul>
            </div>
            <div className="p-6">
                <h2 className="text-lg md:text-xl lg:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Description: -
                </h2>
                <p className="text-xs md:text-sm lg:text-base text-gray-700">
                    Sun trust <span className="font-bold">Bank</span> provides investment management, retail and commercial banking, consumer finance and investment
                    banking services. I was involved in the ‘Online Personal Banking” module of the website where account holders can
                    check their accounts and balances by entering their username and password. They are also provided the service of
                    instant transfers of funds between checking and savings accounts, online service, changing profile etc.
                </p>
            </div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Responsibilities:-
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Determine test objectives and standards by studying Software requirements and writing test plans, test cases
and test scripts and working closely with the Developers in the review and modification of the product and its
specifications using Agile-testing methodology.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Involved in designing and developing the Maven framework/automation scripts for new enhancements using
                        Selenium WebDriver and TestNG prior to agile release and resolved the issues identified in automation.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Collected weekly status reports to ensure that all deliverables are on time and schedule.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Created Use Cases from the list of requirements and prepared use case diagrams.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Developed Test cases manually and generated Automation Scripts using open-source tools like Selenium Web
Driver, TestNG, Maven, Jenkins, and Rest assured API, Postman, SoapUI.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Performed QA Automation testing, Integration and Regression testing activities for new releases in an End-toEnd environment for Point-of-Sale application

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Involved in Designing & Developing data driven framework. test script for regression test suite to send the
regression result automatically.



                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Wrote Selenium script for taking screenshot and used Actions class to perform mouse and keyboard events.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Used Apache POI to read data from external sources and provide them as parameters to the locators in the test
                        script
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Used Jira for defect management.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Reviewed database test cases according to assigned Requirements to validate reports by retrieving data with
                        complex SQL queries from oracle database.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                        Prepared detailed reports of Bugs and Defects, Pass/Fail reports, and comparison analysis, and interacted with
                        the development teams, application and data architecture team.
                        </span>
                    </li>

                </ul>
            </div>
        </div>
    )
}
