import React from 'react'
import { FaCircle } from 'react-icons/fa'

export default function RPADeveloper() {
    return (
        <div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Project Experience
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex flex-row items-start">
                        <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Client
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Virginia Credit Union, Midlothian, VA
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                        <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Duration
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Mar 2019 – Till Now
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                        <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Technologies
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : UiPath Studio.
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                        <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Role
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : RPA Developer
                        </span>
                    </li>
                </ul>

            </div>

            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Responsibilities:-
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            As a Senior developer coordinated with external resources, customers, and vendors for the
                            flawless execution of projects. Expertise in accessibility testing as per Bank compliance.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Mentoring, coaching, and setting direction for team members and project Managers Delivering regular feedback
                            to management of project team performance.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Leading customer communication and managing the expectations and hiring, onboarding, and mentoring new
                            team members facilitating requirement scoping, evaluating design/alternatives, resolving issues and improving
                            process/performance mapping clients’ requirements through gap analysis
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Working as a SPOC for all assigned projects; effectuating best practice into engineering design and management
                            delivery


                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Developing project plans, estimating time requirements, establishing deadlines, monitoring milestones, tracking
                            project lifecycle, reporting issues that impact project progress to both client & top management
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Steering quality audits for operational efficiency levels and recommending measures for improvement in
                            bottom-line Outlining project requirements, scope, goals, cost, deliverables, and sign-off from key stakeholders

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Ensuring that all technical and design risks are identified and appropriately managed to meet project
                            requirements.


                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Utilize UiPath Document Understanding framework to build and optimize OCR (Optical Character Recognition)
                            and machine learning models for data extraction.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Implement and fine-tune intelligent document processing (IDP) capabilities to ensure high accuracy and
                            efficiency.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Have to create / review the object and process based on the SDD and PDD and OH documents.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Getting all the required details from Business team as DILO session regarding the assigned to project.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Always should be update the details about the project/Defect/Enhancements thorough tracking tool.

                        </span>
                    </li>

                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Proficient in analyzing requirements, preparation of testing and automation strategy, test plans, test cases and
                            design and implementation of automation .


                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Handling different type’s exception and sending the emails to client based on the exception type.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Using collection inside collection and stored and accessed data from the sub collection and main collection

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            After completion of project, must send emails to deployment team to deploy the project into production and
                            shared about the process requirements to Production support team.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Used baseline/power bi/tableau Gateways to keep the project details and kick out reports up to date.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Attending walkthrough of the process & creating test scenarios, query logs & Bot design

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Prepared Process Design documents (PDD) related to Workflow process. Estimating the effort for the task to
                            accomplish.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Implement the ReFramework by consolidating all the workflows and publish in Orchestrator.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Handover the workflow to client for UAT to verify and validate the code and then get UAT Signed off from the
                            client.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Move to the code for Deployment in Production and also give production support whenever required to the
                            client.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Ensure all automated processes comply with regulatory standards and organizational policies.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Manage and prioritize multiple automation projects, ensuring timely delivery and adherence to project
                            timelines.

                        </span>
                    </li>

                </ul>
            </div>
        </div>
    )
}
