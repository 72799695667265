import React from 'react'
import { FaCircle } from 'react-icons/fa'

export default function QAEngineer() {
    return (
        <div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">

                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Client
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Cigna Hartford , Connecticut

                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Duration
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Aug 2017 - Mar 2018
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Technologies
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : Agile, Selenium WebDriver, Selenium, C#, Specflow, Rest API Jenkins, SQL, Unix ,Git, GitHub,
                        </span>
                    </li>
                    <li className="flex flex-row items-start">
                    <span className="min-w-[8rem] text-xs md:text-sm lg:text-base font-bold">
                            Role
                        </span>
                        <span className="text-xs md:text-sm lg:text-base font-bold">
                            : QA Engineer
                        </span>
                    </li>
                </ul>
            </div>
            <div className="p-6">
                <h2 className="text-lg md:text-xl lg:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Description: -
                </h2>
                <p className="text-xs md:text-sm lg:text-base text-gray-700">
                    Software Quality Engineer (SDET) Automation with Selenium, C#, Specflow, and Manual test including Functional,
                    Integration, Regression, E2E and Backend Database testing, on healthcare project.
                </p>
            </div>
            <div className="p-6">
                <h2 className="text-xl md:text-2xl font-semibold border-b border-gray-800 pb-2 mb-4">
                    Responsibilities:-
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Healthcare project is under Agile environment, attended daily stand-up meetings, provided daily status on
                            manual and automation test results. Attended sprint plan meetings to review the user stories and story points.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Participated in business requirements "walk-through" and provided feedback to ensure product specifications.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Used Selenium WebDriver with java plug-ins to generate automated test scripts for functional testing, GUI testing
                            and enhancing the existing scripts.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Involved in building automation regression test suite for the application which can be executed during each
                            sprint release.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            End to End functional and regression testing of all system components of the application and identifying test
                            scripts for UAT.

                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Conducted Automation/manual testing including Backend database testing using the Standards, guidelines, and
                            structured methodology in testing the application. Analyzing Business Requirements, writing test plan, test cases
                            and executing using Rally for project tracking system.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Developed Web automation test scripts using C# language, Microsoft visual studio 19, Selenium WebDriver,
                            Specflow (Gherkin) and XUnit etc. based on the design pattern Page Object Model for reusability.


                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Reviewed database test cases according to assigned Requirements to validate reports by retrieving data with
                            complex SQL queries from oracle database.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <span className="me-5 mt-2.5 text-xs md:text-sm lg:text-base">
                            <FaCircle className="text-[6px] " />
                        </span>
                        <span className="text-xs md:text-sm lg:text-base">
                            Prepared detailed reports of Bugs and Defects, Pass/Fail reports, and comparison analysis, and interacted with
                            the development teams, application and data architecture team.
                        </span>
                    </li>


                </ul>
            </div>
        </div>
    )
}
